.home-page {
  .home-content {
    width: 1000px;
    .text-box {
      .title {
        font-size: 5rem;
        text-shadow: rgba(0, 0, 0, 0.498039) -1px -1px 0px, rgba(0, 0, 0, 0.498039) -1px 1px 0px, rgba(0, 0, 0, 0.498039) 1px 1px 0px, rgba(0, 0, 0, 0.498039) 1px -1px 0px;
      }
      .des {
        font-size: 1.75rem;
        line-height: 2.75rem;
      }
      .imgs {
        a {
          display: inline-block;
          margin: 0 0.5rem;
          font-size: 0;
          img {
            width: 4rem;
          }
        }
      }
    }
    .icon-box {
      .icon {
        width: 26rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home-page {
    align-items: flex-start !important;
    .home-content {
      max-width: 600px;
      flex-direction: column-reverse;
      justify-content: center;
      .text-box {
        .title {
          font-size: 3rem;
        }
        .des {
          font-size: 1.3rem;
          line-height: 2rem;
        }
        .imgs {
          margin-top: 1.5rem;
          margin-bottom: 2.5rem;
          a {
            img {
              width: 2rem;
            }
          }
        }
      }
      .icon-box {
        padding-left: 0;
        .icon {
          width: 13rem;
        }
      }
    }
  }
}