.app-page {
  .nav-box {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
    .nav-content {
      .toggle-box {
        display: none;
        color: rgba(255,255,255,.5);
        border: 1px solid rgba(255,255,255,.1);
        padding: 0.3rem 0.8rem;
      }
      .logo-box {
        .logo {
          width: 14rem;
        }
      }
      .menu-box {
        & > div {
          opacity: 0.5;
          margin: 0 1.8rem;
          cursor: pointer;
          &.active {
            opacity: 1;
          }
          &:not(.active) {
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .menu-list {
      display: none;
    }
  }
  .main-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
  }
  .main-bg {
    background-image: url('../src/assets/img/bg.webp');
    background-position: left center;
    background-repeat: no-repeat;
    opacity: 0.2;
  }
}

@media screen and (max-width: 1000px) {
  .app-page {
    .nav-box {
      .nav-content {
        justify-content: space-between;
        .toggle-box {
          display: flex;
        }
        .logo-box {
          .logo {
            width: 8rem;
          }
        }
        .menu-box {
          display: none;
        }
      }
      .menu-list {
        display: flex;
        flex-direction: column;
        text-align: center;
        overflow: hidden;
        transition: all ease-in-out 400ms;

        &.inactive  {
          height: 0 !important;
        }
        &.active {
          height: auto !important;
          padding: 1rem 0;
        }
        & > div {
          height: 3rem;
          line-height: 3rem;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}