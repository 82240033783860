@import './variable.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  list-style: none;
  text-decoration: none;
  user-select: none;
}

html, body, #root {
  width: 100%;
  height: 100%;
  min-width: 320px;
  overflow: hidden;
  color: $white;
  background: $success;
}

html {
  font-size: 16px;
}

@media screen and (max-width: 375px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 14px;
  }
}
